import { get } from "lodash";
import { FIELDS_FOR_LISTING_PAGE, FIELDS_FOR_STORY_PAGE } from "./constants";
import cookie from "cookie";
import wretch from "wretch";
import Cookies from "universal-cookie";
const NewCookies = new Cookies();

export function fetchLatestStories(pageNumber, perPage, params = {}) {
  return global
    .wretch("/api/v1/stories")
    .query({
      fields: FIELDS_FOR_STORY_PAGE,
      limit: perPage,
      offset: perPage * pageNumber,
      ...params
    })
    .get()
    .json();
}

export function fetchCollection(collectionSlug, pageNumber, perPage, params, type = "story") {
  if (!collectionSlug) {
    return;
  }

  return wretch(`/api/v1/collections/${collectionSlug}`)
    .query({
      limit: perPage,
      "item-type": type,
      offset: perPage * pageNumber,
      ...params
    })
    .get()
    .json();
}

export function fetchRelatedStories(story = {}) {
  const sectionId = get(story, ["sections", 0, "id"], 0);
  return global
    .wretch(`/api/v1/stories/${story.id}/related-stories`)
    .query({
      "section-id": sectionId,
      fields: FIELDS_FOR_LISTING_PAGE
    })
    .get()
    .json(response => response["related-stories"]);
}

export function hasStoryAccess(storyId) {
  return global
    .wretch(`/api/vikatan/v1/stories/${storyId}/access`)

    .get()
    .json()
    .catch(error => {
      console.error(error);
      return { granted: false };
    });
}

export function hasCollectionAccess(collectionId) {
  return global
    .wretch(`/api/vikatan/v1/collections/${collectionId}/access`)
    .get()
    .json()
    .catch(error => {
      console.error(error);
      return { granted: false };
    });
}

export function fetchLoggedInUser() {
  return global
    .wretch("/api/vikatan/v1/users/me")
    .get()
    .json(response => response.user);
}

export function incrementPremiumStoriesRead(storyIds) {
  if (Array.isArray(storyIds)) {
    return global.wretch("/api/vikatan/v1/stories/pingback").post({ storyIds });
  }
  if (typeof storyIds === "string") {
    return global.wretch(`/api/vikatan/v1/stories/${storyIds}/pingback`).post();
  }
}

export function fetchStoryAttributes(story) {
  return global
    .wretch(`/api/v1/stories/${story.id}/attributes`)
    .get(response => response.collections)
    .json();
}

export function fetchPaywallAttributes() {
  const type = {
    type: "A",
    "std-link": "https://store.vikatan.com/"
  };
  return global
    .wretch(`https://image.vikatan.com/pw-beta?${Math.random()}`)
    .get(response => response)
    .json()
    .catch(() => type);
}

export function fetchAuthorDetails(story) {
  const authorId = get(story, ["authors", 0, "id"]);
  return global
    .wretch(`/api/v1/authors/${authorId}`)
    .get()
    .json(response => response.author);
}
export function fetchAuthorCount(story) {
  const authorId = story;
  return global
    .wretch(`/api/v1/advanced-search?aggregations=story-attributes.articletype&author-id=${authorId}`)
    .get()
    .json(response => response);
}

export function fetchStories(story) {
  return global
    .wretch(`/api/v1/stories/${story}`)
    .get()
    .json(response => response);
}

export function fetchvuuklegetCommentCount(storyId) {
  return new Promise((resolve, reject) => {
    global
      .wretch(`https://api.vuukle.com/api/v1/Comments/getCommentCountListByHost?host=vikatan.com&articleIds=${storyId}`)
      .options({ credentials: "include", mode: "cors" })
      .get()
      .json(response => resolve(response));
  });
}

export function fetchStory(story) {
  return new Promise((resolve, reject) => {
    global
      .wretch(`/api/v1/stories/${story}`)
      .get()
      .json(response => resolve(response))
      .catch(error => {
        reject(error);
      });
  });
}

export function fetchOrder(payload) {
  return global
    .wretch(`https://store.vikatan.com/razorpay/react_orders.php`)
    .query(payload)
    .get()
    .json(response => response)
    .catch(error => error);
}
export function fetchRazarPay(payload) {
  return global
    .wretch(`https://api.vikatan.com/subscription/web_paywall_orders.php`)
    .headers({ "Content-Type": "application/json", "x-auth-key": "wadBSGthR5XrQ9hR" })
    .post(payload)
    .json(response => response)
    .catch(error => error);
}
export function fetchSelectOrder(payload) {
  return global
    .wretch(`https://api.vikatan.com/paywall-special-issue-purchase`)
    .headers({
      "Content-Type": "application/json",
      "x-auth-key": "wadBSGthR5XrQ9hR"
    })
    .post(payload)
    .json(response => response)
    .catch(error => error);
}
export function requestValidatePayment(args) {
  return global
    .wretch(`https://api.vikatan.com/subscription/validate_order.php`)
    .headers({ "Content-Type": "application/json", "x-auth-key": "wadBSGthR5XrQ9hR" })
    .post(args)
    .json(response => response)
    .catch(error => error);
}

export function fetchSectionStories(story = {}) {
  const sectionId = get(story, ["sections", 0, "id"], 0);
  const storyId = get(story, ["id"], null);
  return global
    .wretch(`/api/v1/stories`)
    .query({
      "section-id": sectionId,
      limit: 6,
      fields: FIELDS_FOR_STORY_PAGE,
      "not-story-content-ids": storyId
    })
    .get()
    .json(response => response["stories"]);
}
export function fetchTrendingStories(pageNumber, perPage, params = {}) {
  return global
    .wretch("/v1/api/dynamic-collection")
    .query({
      "story-fields": FIELDS_FOR_LISTING_PAGE,
      limit: perPage,
      offset: perPage * pageNumber,
      slug: "trending-at-vikatan",
      ...params
    })
    .get()
    .json();
}

export function fetchRfmScore(user = null, thinmintid = null) {
  let uaid = get(user, ["userId"], "");
  return global
    .wretch(`https://rt.vikatan.com/v3/?uid=${uaid}&thinmintid=${thinmintid}&profile=full`)
    .get()
    .json()
    .catch(error => error);
}

export function fetchLatestAtVikatan(pageNumber, perPage, params = {}) {
  return global
    .wretch("/api/v1/collections/latest-news-collection")
    .query({
      fields: FIELDS_FOR_STORY_PAGE,
      limit: perPage,
      offset: perPage * pageNumber,
      ...params
    })
    .get()
    .json();
}
export function fetchgoldRate() {
  return wretch(`https://image.vikatan.com/gold_rate/live_update/22_gold_rate.json`)
    .get()
    .json(response => response);
}

export function fetchsilverRate() {
  return wretch(`https://image.vikatan.com/silver_rate/live_update/rates.json`)
    .get()
    .json(response => response);
}

export function fetchRecentMatch() {
  return wretch(`https://image.vikatan.com/ipl-2020/recent-matches.json`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchPointTable() {
  return wretch(`https://image.vikatan.com/ipl-2020/points-table.json`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchIPLPointTable24() {
  return wretch(`https://image.vikatan.com/ipl/2024/points.json`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchIPLPointTable25() {
  return wretch(`https://image.vikatan.com/ipl/2025/points.json`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchIplPointTable() {
  return wretch(`https://image.vikatan.com/ipl/2023/points.json`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchT20PointTable24() {
  return wretch(`https://image.vikatan.com/cricket/2024/t20/points.json`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchT20GroupPointTable24() {
  return wretch(`https://image.vikatan.com/cricket/2024/t20/points_league.json`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchIccTrophyPointTable25() {
  return wretch(`https://image.vikatan.com/cricket/2025/icc_champions_trophy/icc_champion_trophy.json`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchOlympicsMedalTable24() {
  return wretch(`https://image.vikatan.com/sports/olympic/medals_list.json`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchMatch(storyname = "iplt20s2_2020_g2") {
  var n = storyname.lastIndexOf("-");
  var slug = storyname.substring(n + 1);
  return wretch(`/v1/api/match-list`)
    .query({
      match: slug
    })
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchFbStory() {
  return wretch(`/common-assets/elect-embed`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchDistrick() {
  return wretch(`/v1/api/districk-list`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchConstituency() {
  return wretch(`/v1/api/constituency-list`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchCandidateResult() {
  return wretch(`https://image.vikatan.com/election/2024/candidates/result_munnani.json`)
    .get()
    .json(response => response);
}

export function fetchStateElectionResult() {
  return wretch(`https://image.vikatan.com/election/2024/ten_state_election_result.json`)
    .get()
    .json(response => response);
}

export function fetchElectionComment(Id) {
  return wretch(`/v1/api/election-comments-list`)
    .query({
      id: Id,
      name: "comment"
    })
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchConstituencyDetail(Id) {
  return wretch(`/v1/api/constituency-detail`)
    .query({
      id: Id,
      name: "constituency"
    })
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchCandidatesList(Id) {
  return wretch(`/v1/api/constituency-detail`)
    .query({
      id: Id,
      name: "candidates"
    })
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchAllCandidatesList() {
  return wretch(`/v1/api/candidate-list`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchPartyList() {
  return wretch(`/v1/api/parties-list`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchTeamPartyList() {
  return wretch(`/v1/api/party-team-list`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchCandidatesPageList(Id) {
  return wretch(`/v1/api/candidatepage-list`)
    .query({
      id: Id
    })
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchSearchCandidatesList(Id) {
  return wretch(`/v1/api/search-candidate-list`)
    .query({
      id: Id
    })
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchpartyWiseCandidatesList(ThogudhiId, partyid, TeamPartyId) {
  return wretch(`/v1/api/party-wise-search-list`)
    .query({
      ThogudhiId: ThogudhiId,
      partyid: partyid || "",
      TeamPartyId: TeamPartyId || ""
    })
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchGamezopCategoryList(Id) {
  return wretch(`/v1/api/gamezop-category-list`)
    .query({
      id: Id
    })
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchGamezopCategoryMenu() {
  return wretch(`/v1/api/gamezop-category-menu-list`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchForyouTopics(user, thinmint) {
  return wretch(`/v1/api/foryou-topics`)
    .query({
      thinmint: thinmint,
      uaid: user
    })
    .get()
    .json(response => response.topics)
    .catch(error => error);
}

export function postForyouTopics(user = "", thinmint = "", topics) {
  const topic = {};
  topic.topics = topics;
  return wretch(`https://api.vikatan.com`)
    .query({
      thinmintid: thinmint,
      uid: user,
      topics: JSON.stringify(topic),
      module: "mynews",
      view: "add_topics_web",
      platform: "web"
    })
    .get()
    .json(response => response)
    .catch(error => error);
}

export function triggerwruEvent(storyId, Uid, actionType, user = false) {
  const params = {};
  params.uid = Uid;
  params.cid = storyId; // Article Id
  params.eid = "EVENT1";
  params.merchantId = "130922";
  params.ip = "";
  params.timestamp = new Date().getTime().toString(); // in millis
  params.eventType = actionType;
  params.recommendation = "wru";
  params.ref = "vikatan infinite scroll";
  params.userAgent = navigator.userAgent;
  params.platform = "web";
  if (user) {
    params.payload = {
      user_details: {
        user_account_id: user.userId
      }
    };
  }

  global
    .wretch("https://alfred.wru.ai/130922/user")
    .post([params])
    .res();
}

export function reqSwgUser(email = "", auth = "") {
  return wretch(`/v1/api/svwvg-reg`)
    .query({
      email: email,
      auth: auth
    })
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchEvRecommendation(sId) {
  const evoEndpoint =
    document.location.hostname.endsWith("vikatan-beta-new.quintype.io") === true
      ? "vik.uat.evolok.net"
      : "ev.vikatan.com";
  return wretch(`https://${evoEndpoint}/acd/api/3.0/content/recommendations`)
    .query({
      ev_sid: sId
    })
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchLoggedInEvolokUser(loggedOutSession) {
  let cookies = cookie.parse(document.cookie);

  return global
    .wretch("/v2/api/users/me")
    .query({
      vuId: cookies["vuid"] || "",
      evSs: cookies["ev_ss"] || "",
      authId: cookies["vikatan[auth_id]"] || "",
      loggedOutSession: loggedOutSession
    })
    .get()
    .json(response => response.data);
}

export function fetchEvolokData(loggedOutSession) {
  let cookies = cookie.parse(document.cookie);

  return global
    .wretch("/v2/evolok/user")
    .query({
      guid: cookies["ev-guid"]
    })
    .get()
    .json(response => response.data);
}

export function fetchEvAuthorize() {
  const hostname = document && document.location.hostname;

  const evoEndpoint = hostname.endsWith("vikatan-beta-new.quintype.io")
    ? "vik.uat.evolok.net"
    : hostname === "vikatan.com"
      ? "ev.vikatan.com"
      : hostname === "www.anandavikatan.com" || hostname === "anandavikatan.com"
        ? "ev.anandavikatan.com"
        : "ev.vikatan.com";
  let cookies = cookie.parse(document.cookie);

  return new Promise((resolve, reject) => {
    wretch(`https://${evoEndpoint}/acd/api/3.0/authorize/json`)
      .post({
        ev_ss: cookies.ev_ss,
        ev_sid: cookies.ev_sid,
        ev_did: cookies.ev_did
      })
      .json(response => {
        let data = response;
        const loggedOut = get(data, ["loggedOut"]);
        let vuId = cookies["vuid"];
        if (vuId) {
          fetchLoggedInEvolokUser(loggedOut)
            .then(res => {
              data.userData = res;
              resolve(data);
            })
            .catch(error => reject(error));
        } else {
          data.userData = {};
          if (loggedOut === false && !cookies["vuid"]) {
            fetchEvolokData(loggedOut)
              .then(res => {
                let evolokData = get(res, ["user"]);
                const getVuid = get(evolokData, ["vuid"], "");
                const current = new Date();
                const nextYear = new Date();
                nextYear.setFullYear(current.getFullYear() + 1);
                const hostname = window.location.hostname.replace(/^www\./, "");
                const domain =
                  hostname.includes(".vikatan.com") || hostname === "vikatan.com" ? ".vikatan.com" : `.${hostname}`;
                NewCookies.set("vuid", getVuid, { path: "/", domain: domain, expires: nextYear });
                resolve(data);
              })
              .catch(error => reject(error));
          }
          resolve(data);
        }
      })
      .catch(error => {
        // Handle error here if needed
        // console.error(error);
        resolve(error); // or resolve with a default value
      });
  });
}

export function fetchVikatanDealsMenu() {
  return global
    .wretch(`/deals-config`)
    .get()
    .json(response => response);
}

export function fetchVikatanCoins() {
  let cookies = cookie.parse(document.cookie);
  let Guid = cookies["ev-guid"];
  if (Guid) {
    return wretch(`/user-coin`)
      .query({
        guId: Guid
      })
      .get()
      .json(response => response)
      .catch(error => error);
  }
}

export function fetchLatestVideos(pageNumber, perPage, params = {}) {
  return global
    .wretch("/api/v1/collections/vikatan-videos")
    .query({
      fields: FIELDS_FOR_STORY_PAGE,
      limit: perPage,
      offset: perPage * pageNumber,
      ...params
    })
    .get()
    .json();
}
export function fetchVikatanTopStories(productID) {
  return global
    .wretch(`https://revaff.com/wp-json/wp/v2/posts?exclude=${productID}&embed=wp:term`)
    .get()
    .json(response => response);
}
export function fetchLocation() {
  let cookies = cookie.parse(document.cookie);
  let evLocation = cookies["ev-location"];
  let vikreferrer = cookies["vikreferrer"];
  if (!vikreferrer) {
    NewCookies.set("vikreferrer", document && document.referrer, { path: "/", domain: ".vikatan.com" });
  }

  if (!evLocation) {
    return wretch(`https://ping.vikatan.com/getip`)
      .headers({ "x-auth-key": "wadBSGthR5XrQ9hR" })
      .get()
      .json(response => {
        const res = {};
        res.ping = {
          ip: response.ip || "",
          data: response
        };

        const hostname = window.location.hostname.replace(/^www\./, "");
        const domain =
          hostname.includes(".vikatan.com") || hostname === "vikatan.com" ? ".vikatan.com" : `.${hostname}`;

        NewCookies.set("ev-location", res, { path: "/", domain: domain });
        return res;
      })
      .catch(error => error);
  } else {
    return JSON.parse(evLocation);
  }
}

export function fetchOneTabLogin(credential) {
  return global
    .wretch("/v1/api/one-tab-login")
    .query({
      credential: credential
    })
    .get()
    .json(response => response);
}

export function fetchVikatanDealsArticle(slug) {
  return global
    .wretch("https://www.vikatan.com/deals-product")
    .query({
      "product-id": slug
    })
    .get()
    .json(response => response);
}

export function fetchGenerateOtp(body, type) {
  return global
    .wretch("/v1/api/login")
    .query({
      body: body,
      type: type
    })
    .get()
    .json(response => {
      return response;
    })
    .catch(error => {
      console.error("Error:", error);
      return error;
    });
}

export function fetchCoinsCookie(payload) {
  return global
    .wretch(`https://api.vikatan.com/vikatan-login-api`)
    .headers({ "Content-Type": "application/json" })
    .post(payload)
    .json(response => response)
    .catch(error => error);
}

export function fetchWCMatchList() {
  return wretch(`https://image.vikatan.com/cricket/tournament/featured_matches.json?${Math.random()}`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchIPLMatchList() {
  return wretch(`https://image.vikatan.com/ipl/2024/ipl_matches_list.json?${Math.random()}`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchWCPointTable() {
  return wretch(`https://image.vikatan.com/cricket/tournament/points.json?${Math.random()}`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchWCScoreTable(key) {
  return wretch(`https://image.vikatan.com/cricket/match/${key}.json?${Math.random()}`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchYTReels() {
  return wretch(`/v1/api/vik-yt-reels`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchYTVideos() {
  return wretch(`/v1/api/vik-yt-videos`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchWCLiveScore(key) {
  return wretch(`https://image.vikatan.com/cricket/match/${key}.json?${Math.random()}`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchWCSchedule() {
  return wretch(`/v1/api/cric-match-schedule`)
    .get()
    .json(response => response)
    .catch(error => error);
}
export function PostSubscriptionLinking(PPID) {
  return wretch(`https://api.vikatan.com/google-subs-link?uid=${PPID}&type=update_subs`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function CheckSubscriptionLinking(uaid) {
  return wretch(`https://api.vikatan.com/google-subs-link?uid=${uaid}&&type=check_user`)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function OtherDomainCollection(data) {
  return wretch(`/v1/api/otherdomain-collection`)
    .query({
      slug: data
    })
    .get()
    .json(response => response)
    .catch(error => error);
}

export function postComments(args) {
  return wretch(`/v2/api/comment`)
    .query(args)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function LoadmoreAudiostories(collectionId, limit, offset) {
  return global
    .wretch(
      `/v1/api/otherdomain-collection?slug=audiobooks-${collectionId}&storyFields=${FIELDS_FOR_STORY_PAGE}&limit=10&storyLimit=${limit}&offset=${offset}`
    )
    .get()
    .json(response => response)
    .catch(error => error);
}

export function PostVikatanplayrating(payload) {
  return global
    .wretch(`/v/api/post-review`)
    .headers({ "Content-Type": "application/json" })
    .post(payload)
    .json(response => response)
    .catch(error => error);
}
export function GetVikatanplayrating(args) {
  const version = `v${Date.now()}`; // Generate a unique version dynamically
  return global
    .wretch(`/v2/api/vikatan/reviews?${version}`)
    .query(args)
    .get()
    .json(response => response)
    .catch(error => error);
}

export function fetchOtherDomainCollection(collectionSlug, pageNumber, perPage, params, type = "story") {
  if (!collectionSlug) {
    return;
  }

  return wretch(`/v1/api/otherdomain-collection`)
    .query({
      limit: perPage,
      offset: perPage * pageNumber,
      slug: collectionSlug,
      ...params
    })
    .get()
    .json();
}
